






function displayAvatar(){
    console.log('k')
    var v = document.getElementById('digital-human-video-container')
    window.uneeqInteractionsOptions = {
        personaShareId: "7844d162-5e8a-41a5-9991-4a1991e10599",
         layoutMode: 'fullScreen',
       
    
        showUserInputInterface: true,
        displayCallToAction: true,
        renderContent: true,
         cameraPosition: "CENTER",
        playWelcome: true,
        enableMicrophone: true,
       
    }
}


